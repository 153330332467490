import React from "react";
import "./About.css";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();
  return (
    <section className="beds-about">
      {location.pathname === "/about-us" ? (
        <h1 className="beds-about__header">Über uns</h1>
      ) : (
        <h2 className="beds-about__header">Über uns</h2>
      )}
      <div className="beds-about__body pad">
        <div className="beds-about__text-container">
          <p className="beds-about__text t-20">
            Wir sind Ihr wichtiger Partner und vertrauenswürdiger Berater in der
            Welt der deutschen Sportwetten und bereit, Ihr Erlebnis nicht nur
            erfolgreich, sondern auch wirklich unterhaltsam zu gestalten. Bei
            uns finden Sie sich in einer Welt voller Analysen und
            aufschlussreicher Entscheidungen wieder, in der jede Entscheidung
            auf Erfahrung und Expertenurteil basiert.
          </p>
          <p className="beds-about__text t-20">
            Unser Team besteht aus Menschen, für die Wetten zu einer echten
            Wissenschaft und Sport zum Leben geworden ist. Wir studieren den
            Markt im Detail, recherchieren Quoten und bieten exklusive Boni an,
            um sicherzustellen, dass Sie das Beste aus jeder Wette herausholen.
            Indem wir Ihr Interesse mit aktuellen Informationen und subtilen
            Strategien wecken, verwandeln wir Begeisterung in sorgfältig
            geplanten Erfolg. Bei uns platzieren Sie nicht nur Wetten – Sie
            ebnen selbstbewusst den Weg zu Siegen und lassen Ihre Rivalen hinter
            sich.
          </p>
        </div>
        <div className="beds-about__img"></div>
      </div>
    </section>
  );
};

export default About;
