import { Link } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  function handleClick() {
    setIsActive(false);
  }

  return (
    <header className="beds-header">
      <div className="beds-header__link-container">
        <Link to="/" className="beds-header__link">
          Startseite
        </Link>
        <Link to="/about-us" className="beds-header__link">
          Über uns
        </Link>
        <Link to="/advantages" className="beds-header__link">
          Vorteile
        </Link>
        <Link to="/how-to-play" className="beds-header__link">
          Wie man spielt
        </Link>
        <Link to="/types-of-competition" className="beds-header__link">
          Arten von Wettbewerben
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="beds-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="beds-header__link" onClick={handleClick}>
              Startseite
            </Link>
            <Link
              to="/about-us"
              className="beds-header__link"
              onClick={handleClick}
            >
              Über uns
            </Link>
            <Link
              to="/advantages"
              className="beds-header__link"
              onClick={handleClick}
            >
              Vorteile
            </Link>
            <Link
              to="/how-to-play"
              className="beds-header__link"
              onClick={handleClick}
            >
              Wie man spielt
            </Link>
            <Link
              to="/types-of-competition"
              className="beds-header__link"
              onClick={handleClick}
            >
              Arten von Wettbewerben
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
