import React from "react";
import "./Main.css";
import About from "../About/About";
import Advantages from "../Advantages/Advantages";
import HowToPlay from "../HowToPlay/HowToPlay";
import Types from "../Types/Types";
import Resp from "../Resp/Resp";

import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="beds-main">
      {/* <MainPopup/> */}
      <section className="beds-main__cover">
        <div className="beds-main__img"></div>
        <h1 className="beds-main__header">
          Entdecken Sie die Welt des Sports und der Spiele
        </h1>
      </section>
      <About />
      <Advantages />
      <HowToPlay />
      <Types />
      <Resp/>
    </main>
  );
};

export default MainCover;
