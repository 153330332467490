import React from "react";

const Privacy = () => {
  return (
    <main>
      <section className="beds-privacy pad">
        <h1 className="t-32">Datenschutzrichtlinie von bedstecasspil.com</h1>
        <p className="t-17">
          Bei bedstecasspil.com, zugänglich über bedstecasspil.com, ist die
          Privatsphäre unserer Besucher eine unserer Hauptprioritäten. Dieses
          Datenschutzrichtliniendokument enthält Arten von Informationen, die
          von bedstecasspil.com gesammelt und aufgezeichnet werden, und wie wir
          sie verwenden.
          <br />
          Wenn Sie weitere Fragen haben oder weitere Informationen zu unserer
          Datenschutzerklärung benötigen, zögern Sie bitte nicht, uns zu
          kontaktieren.
        </p>
        <h2 className="t-32">Protokolldateien</h2>
        <p className="t-17">
          bedstecasspil.com folgt einem Standardverfahren für die Verwendung von
          Protokolldateien. Diese Dateien zeichnen Besucher auf, wenn sie
          Websites besuchen. Alle Hosting-Unternehmen tun dies, ebenso wie
          einige Analysen der Hosting-Dienste. Zu den durch Protokolldateien
          gesammelten Informationen gehören Internetprotokolladressen (IP),
          Browsertyp, Internetdienstanbieter (ISP), Datums- und Zeitstempel,
          Verweis-/Ausstiegsseiten und möglicherweise die Anzahl der Klicks.
          Diese werden nicht mit personenbezogenen Daten verknüpft. Der Zweck
          der Informationen besteht darin, Trends zu analysieren, die Website zu
          verwalten, die Bewegungen der Benutzer auf der Website zu verfolgen
          und demografische Informationen zu sammeln. Unsere
          Datenschutzerklärung wurde mit Hilfe des Privacy Policy Generators
          erstellt.
        </p>
        <h2 className="t-32">Unsere Werbepartner</h2>
        <p className="t-17">
          Einige Werbetreibende auf unserer Website verwenden möglicherweise
          Cookies und Web Beacons. Unsere Werbepartner sind unten aufgeführt.
          Jeder unserer Werbepartner verfügt über eigene Datenschutzrichtlinien
          für seine Richtlinien in Bezug auf Benutzerdaten. Für einen
          einfacheren Zugriff haben wir unten Hyperlinks zu ihren
          Datenschutzrichtlinien eingefügt.
        </p>
        <h2 className="t-32">Datenschutzrichtlinie</h2>
        <p className="t-17">
          In dieser Liste finden Sie die Datenschutzbestimmungen für jeden
          Werbepartner von bedstecasspil.com.
          <br />
          Ad-Server oder Ad-Netzwerke von Drittanbietern verwenden Technologien
          wie Cookies, JavaScript oder Web Beacons, die in ihren jeweiligen
          Anzeigen verwendet werden, und Links, die auf bedstecasspil.com
          erscheinen und direkt an den Browser der Benutzer gesendet werden. In
          diesem Fall erhalten sie automatisch Ihre IP-Adresse. Diese
          Technologien werden verwendet, um die Wirksamkeit ihrer Werbekampagnen
          zu messen und/oder um die Werbeinhalte zu personalisieren, die Sie auf
          den von Ihnen besuchten Websites sehen.
          <br />
          Bitte beachten Sie, dass bedstecasspil.com keinen Zugriff auf oder
          keine Kontrolle über diese von Drittanbietern verwendeten Cookies hat.
        </p>
        <h2 className="t-32">Datenschutzrichtlinie Dritter</h2>
        <p className="t-17">
          Die Datenschutzbestimmungen von bedstecasspil.com gelten nicht für
          andere Werbetreibende oder Websites. Wir empfehlen Ihnen daher, für
          detailliertere Informationen die Datenschutzrichtlinien dieser
          Drittanbieter-Adserver zu konsultieren. Es kann deren Vorgehensweisen
          und Anweisungen zum Deaktivieren bestimmter Optionen enthalten.
          <br />
          Sie können Cookies über Ihre individuellen Browseroptionen
          deaktivieren. Ausführlichere Informationen zur Cookie-Verwaltung mit
          bestimmten Webbrowsern finden Sie auf den jeweiligen Websites der
          Browser. Was sind Cookies?
        </p>
        <h2 className="t-32">Informationen für Kinder</h2>
        <p className="t-17">
          Ein weiterer Schwerpunkt unserer Arbeit ist der Schutz von Kindern bei
          der Nutzung des Internets. Wir ermutigen Eltern und
          Erziehungsberechtigte, ihre Online-Aktivitäten zu beobachten, daran
          teilzunehmen und/oder sie zu überwachen und zu leiten.
          <br />
          bedstecasspil.com sammelt wissentlich keine personenbezogenen Daten
          von Kindern unter 13 Jahren. Wenn Sie glauben, dass Ihr Kind diese Art
          von Informationen auf unserer Website bereitgestellt hat, empfehlen
          wir Ihnen dringend, sich umgehend mit uns in Verbindung zu setzen, und
          wir werden unser Bestes tun, um solche Informationen umgehend aus
          unseren Aufzeichnungen zu entfernen.
        </p>
        <h2 className="t-32">Nur Online-Datenschutzerklärung</h2>
        <p className="t-17">
          Diese Datenschutzrichtlinie gilt nur für unsere Online-Aktivitäten und
          gilt für Besucher unserer Website in Bezug auf die Informationen, die
          sie auf bedstecasspil.com geteilt und/oder gesammelt haben. Diese
          Richtlinie gilt nicht für Informationen, die offline oder über andere
          Kanäle als diese Website gesammelt werden.
        </p>
        <h2 className="t-32">Zustimmung</h2>
        <p className="t-17">
          Durch die Nutzung unserer Website stimmen Sie unserer
          Datenschutzerklärung zu und stimmen deren Allgemeinen
          Geschäftsbedingungen zu.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
