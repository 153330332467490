import React from "react";
import "./HowToPlay.css";
import { useLocation } from "react-router-dom";

const HowToPlay = () => {
  const location = useLocation();
  return (
    <section className="beds-how">
      <div className="beds-how__body pad">
        {location.pathname === "/how-to-play" ? (
          <h1 className="beds-how__header">Wie man spielt</h1>
        ) : (
          <h2 className="beds-how__header">Wie man spielt</h2>
        )}
        <ul className="beds-how__list">
          <li className="beds-how__item">
            <p className="t-32">1</p>
            <p className="t-24 col-orange">Erstellen Sie ein Konto</p>
            <p className="t-20">
              Registrieren Sie sich auf unserer Website, um alle Möglichkeiten
              zu entdecken und spezielle Boni zu erhalten.
            </p>
          </li>
          <li className="beds-how__item">
            <p className="t-32">2</p>
            <p className="t-24 col-orange">Guthaben aufladen</p>
            <p className="t-20">
              Zahlen Sie Geld mit der für Sie bequemsten Methode ein. Wir bieten
              viele sichere Zahlungsmöglichkeiten an.
            </p>
          </li>
          <li className="beds-how__item">
            <p className="t-32">3</p>
            <p className="t-24 col-orange">Wählen Sie eine Veranstaltung</p>
            <p className="t-20">
              Durchstöbern Sie unsere Liste von Sportereignissen und wählen Sie
              das aus, auf das Sie wetten möchten.
            </p>
          </li>
          <li className="beds-how__item">
            <p className="t-32">4</p>
            <p className="t-24 col-orange">Platzieren Sie Ihre Wette</p>
            <p className="t-20">
              Geben Sie den Betrag und die Quoten an und bestätigen Sie dann
              Ihre Wette.
            </p>
          </li>
          <li className="beds-how__item">
            <p className="t-32">5</p>
            <p className="t-24 col-orange">Verfolgen Sie das Spiel</p>
            <p className="t-20">
              Beobachten Sie das Spiel in Echtzeit und genießen Sie die Spannung
              des Geschehens.
            </p>
          </li>
          <li className="beds-how__item">
            <p className="t-32">6</p>
            <p className="t-24 col-orange">Gewinnen und Geld abheben</p>
            <p className="t-20">
              Wenn Ihre Wette erfolgreich ist, können Sie Ihre Gewinne sofort
              abheben.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HowToPlay;
