import React from "react";
import "./Resp.css";
import apple from "../../images/appstore.svg";
import google from "../../images/google-play.svg";
import { Link, useLocation } from "react-router-dom";

const Resp = () => {
  const location = useLocation();
  return (
    <section className="beds-resp">
      <div className="beds-resp__body pad">
        {location.pathname === "/responsible-gaming" ? (
          <h1 className="beds-resp__header">Verantwortungsvolles Spielen</h1>
        ) : (
          <h2 className="beds-resp__header">Verantwortungsvolles Spielen</h2>
        )}
        <p className="t-20 text-center">
          Wir halten uns streng an die Prinzipien des verantwortungsvollen
          Spielens und setzen alles daran, diese umzusetzen. Leider erkennen
          nicht alle Spieler die Bedeutung eines verantwortungsvollen Ansatzes
          beim Glücksspiel und die Auswirkungen ihrer Entscheidungen auf ihr
          Leben. Um Spieler, die Schwierigkeiten haben, zu schützen, haben wir
          eine Reihe strenger Maßnahmen eingeführt:
        </p>
        <ul className="beds-resp__list">
          <li className="t-20 col-orange">Strenger Alterskontroll</li>
          <li className="t-20 col-orange">
            Zeitliche Begrenzungen für Spielsitzungen
          </li>
          <li className="t-20 col-orange">Einzahlungslimits</li>
          <li className="t-20 col-orange">Selbstbeschränkungsmöglichkeiten</li>
          <li className="t-20 col-orange">
            Qualifizierte Unterstützung für Menschen mit Spielsucht
          </li>
        </ul>
        <p className="t-20 text-center">
          Wenn Sie feststellen, dass Sie Probleme mit dem Glücksspiel haben,
          zögern Sie nicht, Hilfe in Anspruch zu nehmen. Wir sind bereit, Sie
          auf Ihrem Weg zur Genesung zu unterstützen und organisieren Termine
          mit Fachleuten. Bei ElitebetHub steht sicheres und unterhaltsames
          Spielen an erster Stelle, und wir freuen uns, jeden neuen Nutzer
          willkommen zu heißen!
        </p>
        <div className="beds-resp__best">
          <p className="t-20 col-orange beds-resp__best-upper">
            bestesCasinospiel
          </p>
          <p className="t-20 beds-resp__best-upper text-center">
            Sportwetten rund um die Uhr immer zur Hand
          </p>
          <p className="t-20">App herunterladen</p>
          <div className="beds-resp__best-links">
            <Link to="#">
              <img src={apple} title="app store" alt="app store" />
            </Link>
            <Link to="#">
              <img src={google} title="google play" alt="google play" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resp;
