import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";
import logoOne from "../../images/begambleaware.svg";
import logoTwo from "../../images/gambling-comission.svg";
import logoThree from "../../images/gam-stop.svg";
import logoFour from "../../images/gam-care.svg";
import payOne from "../../images/pay-1.svg";
import payTwo from "../../images/pay-2.svg";
import payThree from "../../images/pay-3.svg";
import payFour from "../../images/pay-4.webp";
import payFive from "../../images/pay-5.svg";
import paySix from "../../images/pay-6.webp";

const Footer = () => {
  return (
    <footer className="beds-footer">
      <div className="beds-footer__body pad">
        <div className="beds-footer__top">
          <Link to="https://www.gambleaware.org/" target="_blank">
            <img src={logoOne} title="be gamble aware" alt="be gamble aware" className="beds-footer__logo"/>
          </Link>
          <Link to="https://www.gamblingcommission.gov.uk/" target="_blank">
            <img
              src={logoTwo}
              title="gambling commission"
              alt="gambling commission"
            />
          </Link>
          <Link to="https://www.gamstop.co.uk/" target="_blank">
            <img src={logoThree} title="gam stop" alt="gam stop" />
          </Link>
          <Link to="https://www.gamcare.org.uk/" target="_blank">
            <img src={logoFour} title="gam care" alt="gam care" />
          </Link>
        </div>
        <p className="t-16">Wir akzeptieren</p>
        <div className="beds-footer__pay">
          <img
            src={payOne}
            title="pay method one"
            alt="pay method one"
            width="100"
            height="50"
          />
          <img
            src={payTwo}
            title="pay method two"
            alt="pay method two"
            width="100"
            height="50"
          />
          <img
            src={payThree}
            title="pay method three"
            alt="pay method three"
            width="100"
            height="50"
          />
          <img
            src={payFour}
            title="pay method four"
            alt="pay method four"
            width="100"
            height="50"
          />
          <img
            src={payFive}
            title="pay method five"
            alt="pay method five"
            width="100"
            height="50"
          />
          <img
            src={paySix}
            title="pay method six"
            alt="pay method six"
            width="100"
            height="50"
          />
        </div>
        <div className="beds-footer__bottom">
          <div className="beds-footer__cookies">
            <p className="t-16 beds-footer__disclaimer">
              bedstecasspil.com - verwendet Cookies für maximalen Komfort. Indem
              Sie auf der Website bleiben, stimmen Sie der Verwendung von
              Cookies zu.
            </p>
            <Link to="/cookies" className="t-16 col-orange">
              Weitere Informationen
            </Link>
          </div>
          <Link to="/privacy" className="t-16 col-orange">
            Datenschutzrichtlinie
          </Link>
          <Link to="/responsible-gaming" className="t-16 col-orange">
            Verantwortungsvolles Spielen
          </Link>
          <img src={age} title="age 18+" alt="age 18+" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
