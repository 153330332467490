import React from "react";
import "./Advantages.css";
import { useLocation } from "react-router-dom";

const Advantages = () => {
  const location = useLocation();
  return (
    <section className="beds-advantahes">
      <div className="beds-advantages__body pad">
        {location.pathname === "/advantages" ? (
          <h1 className="beds-advantahes__header">Vorteile</h1>
        ) : (
          <h2 className="beds-advantahes__header">Vorteile</h2>
        )}
        <ul className="beds-advantahes__list">
          <li className="beds-advantahes__item">
            <p className="t-20 col-orange">Höchstquoten</p>
            <p className="t-20">
              Wir bieten Ihnen die besten Quoten für eine breite Palette von
              Sportereignissen, damit Sie aus jeder Wette das Maximum
              herausholen können. Unsere Quoten werden sorgfältig analysiert, um
              wettbewerbsfähig zu sein und Ihnen echte Vorteile zu bieten –
              egal, ob Sie auf große Turniere oder kleinere Veranstaltungen
              wetten.
            </p>
          </li>
          <li className="beds-advantahes__item">
            <p className="t-20 col-orange">Exklusive Boni</p>
            <p className="t-20">
              Für unsere Kunden haben wir ein System aus exklusiven Boni und
              Angeboten entwickelt, das Ihnen einen zusätzlichen Vorteil
              verschafft. Diese Boni sind einzigartig und werden regelmäßig
              aktualisiert, sodass Sie immer die besten Konditionen genießen und
              Ihre Gewinnchancen maximieren können. Wir sorgen dafür, dass jeder
              Kunde von erweiterten Möglichkeiten und exklusiven Vorteilen
              profitiert.
            </p>
          </li>
          <li className="beds-advantahes__item">
            <p className="t-20 col-orange">Effektive Strategien</p>
            <p className="t-20">
              In unserem Arsenal finden Sie erprobte Strategien und Tipps von
              erfahrenen Experten im Bereich Sportwetten. Wir wissen, welche
              Ansätze wirklich funktionieren, und helfen Ihnen dabei, diese mit
              Bedacht anzuwenden. Diese Strategien ermöglichen es Ihnen nicht
              nur, teilzunehmen, sondern auch zu gewinnen, indem Sie jede Wette
              strategisch und mit Selbstvertrauen angehen.
            </p>
          </li>
          <li className="beds-advantahes__item">
            <p className="t-20 col-orange">Aktuelle Informationen</p>
            <p className="t-20">
              Die Aktualität der Informationen ist ein entscheidender Faktor im
              Sportwettenbereich. Wir aktualisieren Daten, Nachrichten und
              Statistiken zu Ereignissen in Echtzeit, damit Sie stets den vollen
              Überblick haben. Mit uns bleiben Sie auf dem Laufenden über die
              neuesten Nachrichten, Änderungen in den Aufstellungen, die
              aktuelle Form der Teams und weitere Faktoren, die die Ergebnisse
              beeinflussen.
            </p>
          </li>
          <li className="beds-advantahes__item">
            <p className="t-20 col-orange">Sichere Transaktionen</p>
            <p className="t-20">
              Ihre Sicherheit ist unsere Priorität. Wir garantieren geschützte
              Transaktionen und hohe Sicherheitsstandards für jede Ihrer Wetten.
              Unsere Verschlüsselungs- und Schutzmethoden gewährleisten vollste
              Vertraulichkeit und Zuverlässigkeit, sodass Sie mit absolutem
              Vertrauen und Ruhe wetten können.
            </p>
          </li>
          <li className="beds-advantahes__item">
            <p className="t-20 col-orange">Support rund um die Uhr</p>
            <p className="t-20">
              Unser Kundenservice ist jederzeit verfügbar und steht Ihnen zu
              jeder Tages- und Nachtzeit zur Verfügung. Ob Sie technische
              Unterstützung oder Rat zu Wetten benötigen – auf die schnelle und
              kompetente Hilfe unserer Spezialisten können Sie sich immer
              verlassen. Wir sind hier, um sicherzustellen, dass Ihre Fragen
              schnell gelöst werden und Sie ohne Verzögerungen weiterspielen
              können.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Advantages;
