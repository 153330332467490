import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="beds-popup-cookie">
        <form className="beds-popup__form">
          <p className="text-center t-17">
            Bitte beachten Sie, dass unsere Website Cookies verwendet, um unsere
            Dienste zu verbessern und Ihr Erlebnis zu personalisieren. Wir
            verwenden Cookies, um Ihre Präferenzen zu speichern, den
            Datenverkehr zu analysieren und die Sicherheit und
            Funktionsfähigkeit unserer Website zu gewährleisten.
          </p>
          <div className="beds-popup__btn-container">
            <a href="/cookies" className="beds-popup__btn-cookie t-20 col-orange">
              Mehr lesen
            </a>
            <p
              className="beds-popup__btn-cookie beds-popup__btn-agree t-20 col-orange"
              onClick={addPopupCookie}
            >
              Akzeptieren
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
