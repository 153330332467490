import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";
import ScrollToTop from "../Scroll/Scroll";
import Types from "../Types/Types";
import HowToPlay from "../HowToPlay/HowToPlay";
import Advantages from "../Advantages/Advantages";
import About from "../About/About";
import Resp from "../Resp/Resp";

function App() {
  return (
    <div className="beds-page">
      <ScrollToTop />
      <Header />
      <Popup />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/responsible-gaming" element={<Resp />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/advantages" element={<Advantages />} />
        <Route path="/how-to-play" element={<HowToPlay />} />
        <Route path="/types-of-competition" element={<Types />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
