import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="beds-privacy pad">
        <h1 className="t-32">Cookie-Richtlinie für bedstecasspil.com</h1>
        <p className="t-17">
          Dies ist die Cookie-Richtlinie für bedstecasspil.com, erreichbar von
          bedstecasspil.com.
        </p>
        <h2 className="t-32">Was sind Cookies</h2>
        <p className="t-17">
          Wie bei fast allen professionellen Websites üblich, verwendet diese
          Website Cookies, bei denen es sich um kleine Dateien handelt, die auf
          Ihren Computer heruntergeladen werden, um Ihre Erfahrung zu
          verbessern. Diese Seite beschreibt, welche Informationen sie sammeln,
          wie wir sie verwenden und warum wir diese Cookies manchmal speichern
          müssen. Wir werden auch erläutern, wie Sie verhindern können, dass
          diese Cookies gespeichert werden. Dies kann jedoch bestimmte Elemente
          der Funktionalität der Website beeinträchtigen oder 'brechen'.
        </p>
        <h2 className="t-32">Wie wir Cookies verwenden</h2>
        <p className="t-17">
          Wir verwenden Cookies aus verschiedenen unten aufgeführten Gründen.
          Leider gibt es in den meisten Fällen keine branchenüblichen Optionen
          zum Deaktivieren von Cookies, ohne die Funktionalität und die
          Funktionen, die sie dieser Website hinzufügen, vollständig zu
          deaktivieren. Es wird empfohlen, alle Cookies aktiviert zu lassen,
          wenn Sie sich nicht sicher sind, ob Sie sie benötigen, falls sie
          verwendet werden, um einen von Ihnen genutzten Dienst bereitzustellen.
        </p>
        <h2 className="t-32">Cookies deaktivieren</h2>
        <p className="t-17">
          Sie können das Setzen von Cookies verhindern, indem Sie die
          Einstellungen in Ihrem Browser anpassen (siehe dazu die Hilfe Ihres
          Browsers). Beachten Sie, dass das Deaktivieren von Cookies die
          Funktionalität dieser und vieler anderer Websites, die Sie besuchen,
          beeinträchtigt. Das Deaktivieren von Cookies führt normalerweise auch
          dazu, dass bestimmte Funktionen und Merkmale dieser Website
          deaktiviert werden. Daher wird empfohlen, Cookies nicht zu
          deaktivieren.
        </p>
        <h2 className="t-32">Die von uns gesetzten Cookies</h2>
        <p className="t-17">
          Cookies, die zur Konfiguration der Website verwendet werden. Um
          sicherzustellen, dass Sie eine gute Erfahrung auf dieser Website
          haben, bieten wir eine Funktionalität, um Ihre Präferenzen
          festzulegen, wie diese Website funktioniert, wenn Sie sie verwenden.
          Um sich an Ihre Präferenzen zu erinnern, müssen wir Cookies setzen,
          damit diese Informationen jedes Mal abgerufen werden können, wenn Sie
          mit einer Seite interagieren, die von Ihren Präferenzen betroffen ist.
        </p>
        <h2 className="t-32">Cookies von Drittanbietern</h2>
        <p className="t-17">
          In einigen speziellen Fällen verwenden wir auch Cookies, die von
          vertrauenswürdigen Drittanbietern bereitgestellt werden. Der folgende
          Abschnitt beschreibt, welche Drittanbieter-Cookies Sie möglicherweise
          über diese Website antreffen.
        </p>
        <p className="t-17">
          Diese Website verwendet Google Analytics, eine der am weitesten
          verbreiteten und vertrauenswürdigsten Analyselösungen im Web, um uns
          zu helfen zu verstehen, wie Sie die Website nutzen und wie wir Ihre
          Erfahrung verbessern können. Diese Cookies können Dinge verfolgen, wie
          lange Sie auf der Website verbringen und welche Seiten Sie besuchen,
          damit wir weiterhin ansprechende Inhalte produzieren können.
        </p>
        <p className="t-17">
          Weitere Informationen zu Google Analytics-Cookies finden Sie auf der
          offiziellen Google Analytics-Seite.
        </p>
        <h2 className="t-32">Weitere Informationen</h2>
        <p className="t-17">
          Hoffentlich hat das die Dinge für Sie geklärt und wie bereits erwähnt,
          ist es normalerweise sicherer, Cookies aktiviert zu lassen, wenn Sie
          sich nicht sicher sind, ob Sie sie benötigen oder nicht, falls sie mit
          einer der Funktionen interagieren, die Sie auf unserer Website
          verwenden.
        </p>
      </section>
    </main>
  );
};

export default Cookies;
