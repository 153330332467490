import React from "react";
import "./Types.css";
import { useLocation } from "react-router-dom";
import one from "../../images/Tennis.webp";
import two from "../../images/fusball.webp";
import three from "../../images/Basketball.webp";
import four from "../../images/Eishockey.webp";

const Types = () => {
  const location = useLocation();
  return (
    <section className="beds-types">
      <div className="beds-types__body pad">
        {location.pathname === "/types-of-competition" ? (
          <h1 className="beds-types__header">Arten von Wettbewerben</h1>
        ) : (
          <h2 className="beds-types__header">Arten von Wettbewerben</h2>
        )}
        <ul className="beds-types__list">
          <li className="beds-types__item">
            <img
              src={one}
              title="Tennis"
              alt="Tennis"
              className="beds-types__img"
            />
            <p className="t-24 col-orange">Tennis</p>
            <p className="t-20">
              Setzen Sie auf wichtige Tennisereignisse wie Wimbledon, die French
              Open und die US Open und verfolgen Sie die spannenden Momente auf
              dem Platz.
            </p>
          </li>
          <li className="beds-types__item">
            <img
              src={two}
              title="Fußball"
              alt="Fußball"
              className="beds-types__img"
            />
            <p className="t-24 col-orange">Fußball</p>
            <p className="t-20">
              Platzieren Sie Wetten auf prestigeträchtige Fußballligen und
              Turniere wie die Premier League, La Liga und die Champions League
              und erleben Sie jedes Spiel zusammen mit Ihren
              Lieblingsmannschaften.
            </p>
          </li>
          <li className="beds-types__item">
            <img
              src={three}
              title="Basketball"
              alt="Basketball"
              className="beds-types__img"
            />
            <p className="t-24 col-orange">Basketball</p>
            <p className="t-20">
              Wählen Sie die aufregendsten Spiele der NBA und europäischen Ligen
              für Ihre Wetten aus und genießen Sie den Rhythmus des Spiels.
            </p>
          </li>
          <li className="beds-types__item">
            <img
              src={four}
              title="Eishockey"
              alt="Eishockey"
              className="beds-types__img"
            />
            <p className="t-24 col-orange">Eishockey</p>
            <p className="t-20">
              Setzen Sie auf packende Eishockeyspiele, darunter NHL und
              internationale Turniere, und tauchen Sie ein in die Atmosphäre der
              eisigen Schlachten.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Types;
